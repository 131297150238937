import React, { useContext, useEffect, useState } from "react";
import Layout from "../layout/layout";
import Seo from "../layout/seo";
import si from "../../resources/assets/search.svg";
import fi from "../../resources/assets/filter.svg";
import ArticleCard from "../layout/article-card";
import { LanguageContext } from "../context/language-context";

const info = {
  default: {
    titolo: "Cerca",
    abstract:
      "In questa pagina puoi cercare tutti gli articoli o pagina del nostro sito",
    descrizione:
      "In questa pagina puoi cercare tutti gli articoli o pagina del nostro sito",
    slug: "/cerca",
  },
  en: {
    titolo: "Search",
    abstract:
      "In this page you can search all the articles or pages of our site",
    descrizione:
      "In this page you can search all the articles or pages of our site",
    slug: "/search",
  },
  de: {
    titolo: "Suchen",
    abstract:
      "Auf dieser Seite können Sie alle Artikel oder Seiten unserer Website suchen",
    descrizione:
      "Auf dieser Seite können Sie alle Artikel oder Seiten unserer Website suchen",
    slug: "/suchen",
  },
};

const translations = {
  default: {
    search: "Cerca",
    advanced_filter: "Filtri Avanzati",
    results_found: "risultati trovati",
    choose_type: "Scegli tipo",
    choose_topic: "Scegli argomento",
    loading: "Caricamento...",
    start_search: "Inizia la ricerca con la barra di ricerca qua sopra",
    page: "Pagina",
  },
  en: {
    search: "Search",
    advanced_filter: "Advanced Filters",
    results_found: "results found",
    choose_type: "Choose type",
    choose_topic: "Choose topic",
    loading: "Loading...",
    start_search: "Start searching with the search bar above",
    page: "Page",
  },
  de: {
    search: "Suche",
    advanced_filter: "Erweiterte Filter",
    results_found: "Ergebnisse gefunden",
    choose_type: "Wählen Sie den Typ",
    choose_topic: "Wählen Sie das Thema",
    loading: "Laden...",
    start_search: "Beginnen Sie mit der Suche mit der Suchleiste oben",
    page: "Seite",
  },
};

const PAGE_CATEGORY = "page";

const searchStories = async (query, category, topic, locale) => {
  const blogCategory = category === PAGE_CATEGORY ? null : category;
  const url = `https://api.storyblok.com/v2/cdn/stories?search_term=${encodeURIComponent(
    query
  )}&version=published&token=MMpLizQ8SQpbr9f3QMOpvwtt&per_page=36&by_slugs=${
    category === PAGE_CATEGORY ? "static-pages/*" : "articles/*,static-pages/*"
  }&language=${locale}${
    blogCategory || topic ? `&with_tag=${[blogCategory, topic].join(",")}` : ""
  }`;
  const res = await fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();
  const results = (data?.stories ?? [])
    .filter(
      (s) =>
        (!topic || s.tag_list.includes(topic)) &&
        (!blogCategory || s.tag_list.includes(blogCategory))
    )
    .slice(0, 12);
  // sort by recency
  results.sort((a, b) => {
    const bd = new Date(
      b?.content?.article_information?.data || b.published_at || 0
    ).getTime();
    const ad = new Date(
      a?.content?.article_information?.data || a.published_at || 0
    ).getTime();
    return bd - ad;
  });
  return results;
};

const categories = [
  "c-approfondimenti",
  "c-Eventi",
  "c-news",
  "c-Career-Story",
  "c-Press",
  "c-whitepaper",
  "c-case-history",
];

const topics = [
  "t-pragmatic-agile",
  "t-culture",
  "t-cloud native",
  "t-agile-software-development",
  "t-DevOps",
  "t-application-modernization",
  "t-fintech",
  "t-AI",
  "t-enterprise-arch-EA",
  "t-retail",
  "t-corporate",
  "t-edu",
  "t-corporate",
  "t-low-code",
];

const SearchComp = ({ data, locale }) => {
  const st = translations[locale];
  const { t } = useContext(LanguageContext);

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [query, setQuery] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");

  const hasResults = Array.isArray(results);
  //   TODO: debug

  const search = async (query) => {
    setLoading(true);
    const data = await searchStories(
      query,
      selectedType,
      selectedTopic,
      locale
    );
    setResults(data);
    setLoading(false);
  };

  useEffect(() => {
    if (query) search(query);
  }, [selectedType, selectedTopic]);

  // load params and perform search on load
  useEffect(() => {
    const params = new URLSearchParams(window?.location.search);
    const urlSearch = params.get("s");
    const urlCat = params.get("cat");
    const urlTopic = params.get("topic");
    if (urlCat) setSelectedType(urlCat);
    if (urlTopic) setSelectedTopic(urlTopic);
    if (urlSearch) {
      setQuery(urlSearch);
      search(urlSearch);
    }
  }, []);

  return (
    <Layout
      languageMappings={{
        default: "/cerca",
        en: "/search",
        de: "/suchen",
      }}
    >
      <Seo
        info={info[locale]}
        lm={{
          default: "/cerca",
          en: "/search",
          de: "/suchen",
        }}
        lang={locale === "it" ? "default" : locale}
      />
      <div className="bg-wsa-lightgray pt-10">
        <div className="flex flex-col gap-3 lg:gap-[18px] py-6 px-4 bg-wsa-lightgray max-w-[1000px] mx-auto text-xs lg:text-lg font-medium">
          <h1 className="hidden lg:block text-wsa-lighterblue text-[44px] font-bold">
            {st.search}
          </h1>
          <div className="flex mb-3 lg:mb-0">
            <input
              type="text"
              className="flex-grow flex items-center text-base !py-0 lg:py-3 px-8 font-semibold !rounded-none"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <button
              aria-label={st.search}
              className="w-[30px] h-[30px] grid place-content-center lg:w-12 lg:h-12 bg-wsa-lighterblue"
              onClick={() => {
                search(query);
              }}
            >
              <img src={si} alt="" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <div className="w-[30px] h-[30px] p-[3px] bg-wsa-lighterblue">
                <img src={fi} alt="" className="w-6 h-6" />
              </div>
              {st.advanced_filter}
            </div>
            {hasResults && (
              <p className="hidden lg:block">
                {results.length} {st.results_found}
              </p>
            )}
          </div>
          <div className="grid gap-3 lg:gap-6 lg:grid-cols-2">
            <label htmlFor="type" className="sr-only">
              {st.choose_type}
            </label>
            <select
              className="py-3 px-4"
              id="type"
              value={selectedType}
              onChange={(e) => {
                setSelectedType(e.target.value);
              }}
            >
              <option value="">{st.choose_type}</option>
              <option value={PAGE_CATEGORY}>{st.page}</option>
              {categories.map((c) => (
                <option value={c}>
                  {t[c] || c.slice(2).replaceAll("-", " ")}
                </option>
              ))}
            </select>
            <label htmlFor="topic" className="sr-only">
              {st.choose_topic}
            </label>
            <select
              className="py-3 px-4"
              id="topic"
              value={selectedTopic}
              onChange={(e) => {
                setSelectedTopic(e.target.value);
              }}
            >
              <option value="">{st.choose_topic}</option>
              {topics.map((c) => (
                <option value={c}>
                  {t[c] || c.slice(2).replaceAll("-", " ")}
                </option>
              ))}
            </select>
          </div>
          {hasResults && (
            <p className="flex justify-end lg:hidden">
              {results.length} {st.results_found}
            </p>
          )}
        </div>
      </div>
      <div className="articoli bg-white mb-3">
        <div className="ws-cont px-8 my-ws flex justify-center">
          {loading || results === null ? (
            <p className="text-black">
              {loading ? st.loading : st.start_search}
            </p>
          ) : (
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-y-6 lg:gap-x-6 lg:gap-y-12">
              {(results ?? []).map((a) => (
                <ArticleCard
                  a={a}
                  key={a?._uid}
                  isStaticPage={a?.content?.component === "Static Page"}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SearchComp;
